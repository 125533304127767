<template>
  <div v-if="isOpen" class="search-modal-wrapper">
    <div ref="modal" class="search-container mt-[21rem]">
      <div class="input-container">
        <div class="input-wrapper variant">
          <Icon name="search" size="24px" stroke="#ffffff" />
          <input v-model="textInput" class="input" type="text" :placeholder="placeholder" />
          <div class="close-icon">
            <Icon v-if="displayModal" name="close" size="24px" @click="emit('close')" />
          </div>
        </div>
      </div>

      <TransitionRoot v-if="useBreakpoints().isMobile" as="template" :show="displayModal">
        <Dialog as="div" class="relative z-[2000]" @close="displayModal = false">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-[2000] w-screen overflow-y-auto">
            <div class="flex min-h-full min-w-full justify-center text-center">
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  class="relative w-full transform overflow-hidden bg-white p-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-full sm:p-6"
                >
                  <div class="m-4 text-center text-base font-semibold">
                    <div>Votre recherche</div>
                    <Icon
                      class="absolute right-4 top-4"
                      name="close"
                      size="24px"
                      @click="displayModal = false"
                    />
                  </div>
                  <div>
                    <Icon
                      name="search"
                      size="24px"
                      stroke="#cecece"
                      class="absolute translate-x-2 translate-y-3"
                    />
                    <Input
                      id="search"
                      v-model="textInput"
                      extra-class="pl-9"
                      type="text"
                      :placeholder="placeholder"
                      @click="displayModal = true"
                    />
                  </div>
                  <div v-if="displayResults" class="mt-4 flex flex-col">
                    <div
                      v-if="
                        destinationsResult?.nbHits === 0 &&
                        journeysResult?.nbHits === 0 &&
                        themesResult?.nbHits === 0 &&
                        articlesResult?.nbHits === 0
                      "
                      class="mx-4 my-12 flex flex-col items-center"
                    >
                      <Icon
                        name="plane-search-bar"
                        width="66px"
                        height="66px"
                        stroke="#0000"
                        class="plane-icon"
                      />

                      <div class="my-10 flex flex-col items-center justify-center">
                        <span>Aucun résultat ?</span>
                        <span class="text-center"
                          >Pas de soucis ! Plongez dans l'aventure avec un clic.</span
                        >
                      </div>

                      <NuxtLink class="button" :href="`/${ROUTE_NAMES.WHEN_WHERE_TO_GO}`"
                        >Guidez-moi</NuxtLink
                      >
                    </div>
                    <div v-else>
                      <div v-if="destinationsResult">
                        <GlobalSearchDestinations
                          :destinations="destinationsResult.hits"
                          :number-of-results="destinationsResult.nbHits"
                        />
                        <hr class="mt-4 w-full" />
                      </div>
                      <div v-if="journeysResult">
                        <GlobalSearchJourneys
                          :journeys="journeysResult.hits"
                          :number-of-results="journeysResult.nbHits"
                          :search="textInput"
                        />
                        <hr class="mt-4 w-full" />
                      </div>
                      <div v-if="themesResult">
                        <GlobalSearchThemes
                          :themes="themesResult.hits"
                          :number-of-results="themesResult.nbHits"
                        />
                        <hr class="mt-4 w-full" />
                      </div>
                      <GlobalSearchArticles
                        v-if="articlesResult"
                        :articles="articlesResult.hits"
                        :number-of-results="articlesResult.nbHits"
                      />
                    </div>
                  </div>
                  <div v-else class="mt-4 flex flex-col">
                    <h1>Destinations populaires</h1>
                    <NuxtLink
                      v-for="country in trendCountries"
                      :key="country.id"
                      :to="`${country.attributes.geographicZones?.data[0]?.attributes.slug}/${country.attributes.slug}`"
                    >
                      {{ country.attributes.name }}
                    </NuxtLink>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <div
        v-else-if="displayModal"
        class="absolute left-0 top-0 mt-[20rem] flex w-full flex-col overflow-y-scroll bg-white p-4 shadow md:p-12 lg:left-[10%] lg:w-4/5"
      >
        <div v-if="displayResults" class="mt-16 flex flex-col">
          <div
            v-if="
              destinationsResult?.nbHits === 0 &&
              journeysResult?.nbHits === 0 &&
              themesResult?.nbHits === 0 &&
              articlesResult?.nbHits === 0
            "
            class="mx-8 my-12 flex flex-col items-center"
          >
            <Icon
              name="plane-search-bar"
              width="66px"
              height="66px"
              stroke="#0000"
              class="plane-icon"
            />

            <div class="my-10 flex flex-col items-center justify-center">
              <span>Aucun résultat ?</span>
              <span>Pas de soucis ! Plongez dans l'aventure avec un clic.</span>
            </div>

            <NuxtLink class="button" :href="`/${ROUTE_NAMES.WHEN_WHERE_TO_GO}`"
              >Guidez-moi</NuxtLink
            >
          </div>
          <template v-else>
            <GlobalSearchDestinations
              v-if="destinationsResult"
              :destinations="destinationsResult.hits"
              :number-of-results="destinationsResult.nbHits"
            />
            <GlobalSearchJourneys
              v-if="journeysResult"
              :journeys="journeysResult.hits"
              :number-of-results="journeysResult.nbHits"
              :search="textInput"
            />
            <GlobalSearchThemes
              v-if="themesResult"
              :themes="themesResult.hits"
              :number-of-results="themesResult.nbHits"
            />
            <GlobalSearchArticles
              v-if="articlesResult"
              :articles="articlesResult.hits"
              :number-of-results="articlesResult.nbHits"
            />
          </template>
        </div>
        <div v-else class="mx-8 my-12 flex flex-col">
          <h1>Destinations populaires</h1>
          <NuxtLink
            v-for="country in trendCountries"
            :key="country.id"
            :to="`${country.attributes.geographicZones?.data[0]?.attributes.slug}/${country.attributes.slug}`"
          >
            {{ country.attributes.name }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { onClickOutside } from '@vueuse/core';
import debounce from 'lodash/debounce';

import { AlgoliaArticle } from '@/lib/algolia-types/AlgoliaArticle';
import { AlgoliaDestination } from '@/lib/algolia-types/AlgoliaDestination';
import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';
import { AlgoliaTheme } from '@/lib/algolia-types/AlgoliaTheme';
import { Country } from '@/lib/strapi-types/Country';

import { useHeaderEvent } from '@/composables/useHeaderEvent';

import ROUTE_NAMES from '@/lib/types/enums/routeNames.enum';
import { SlugItem } from '@/lib/types/models/common';

defineProps<{
  isOpen: boolean;
  title?: string;
  placeholder?: string;
  primaryButton?: string;
  options: SlugItem[];
  isLoading?: boolean;
  buttonText?: string;
  trendCountries?: Country[];
}>();

const emit = defineEmits<(event: 'close') => void>();

function onEscapeKeyDown(e: KeyboardEvent): void {
  if (e.key === 'Escape') {
    emit('close');
  }
}

onMounted(() => {
  document.addEventListener('keydown', onEscapeKeyDown);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', onEscapeKeyDown);
});

const { headerEventPayload } = useHeaderEvent();
const textInput = ref('');
const displayResults = ref(false);
const displayModal = ref(true);

const modal = ref(null);
onClickOutside(modal, () => {
  emit('close');
});

const textInputLength = computed(() => textInput.value.length);

const { result: destinationsResult, search: destinationSearch } =
  useAlgoliaSearch<AlgoliaDestination>('destination');

const { result: journeysResult, search: journeySearch } =
  useAlgoliaSearch<AlgoliaJourney>('journey');

const { result: themesResult, search: themeSearch } = useAlgoliaSearch<AlgoliaTheme>('theme');

const { result: articlesResult, search: articleSearch } =
  useAlgoliaSearch<AlgoliaArticle>('article');

function handleInputChange(value: string): void {
  if (value.length < 3) {
    return;
  }
  destinationSearch({
    query: value
  });
  journeySearch({
    query: value
  });
  themeSearch({
    query: value
  });
  articleSearch({
    query: value
  });
}
watch([destinationsResult, journeysResult, themesResult, articlesResult], () => {
  if (textInputLength.value >= 3) {
    displayResults.value = true;
  }
});
watch(textInput, debounce(handleInputChange, 300));

watch(textInputLength, value => {
  if (value < 3) {
    displayResults.value = false;
  }
});

watch(headerEventPayload, newPayload => {
  if (newPayload) {
    displayModal.value = true;
  }
});
</script>
<style lang="scss" scoped>
@use 'sass:math';
@use '$/border-radius.scss';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/shadows.scss';
@use '$/text-ellipsis.scss';
@use '$/button.scss';

.search-modal-wrapper {
  position: absolute;
  z-index: 1500;
  top: 0;
  bottom: 0;

  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100vh;

  .search-container {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    width: 80%;
    max-width: 1040px;

    background: #fff;

    .input-container {
      $height: 72px;
      $mobile-height: 56px;

      @include breakpoints.mobile() {
        height: 40px;
        padding: 2px 8px 2px 20px;

        .input-wrapper {
          .input {
            height: unset !important;
            padding: 10px !important;
            font-size: 14px !important;
            border: none !important;

            &::placeholder {
              font-size: 14px !important;
              text-align: center;
            }
          }
        }
      }

      position: relative;
      z-index: 1000;

      width: 100%;
      max-width: 800px;
      height: 68px;
      padding: 8px 8px 8px 20px;

      background: rgb(255 255 255 / 40%);
      backdrop-filter: blur(10px);
      border-radius: border-radius.$small;

      .input-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        .input {
          width: 100%;

          font-size: 18px;
          color: white;

          background: transparent;
          border: none;
          outline: none;

          &::placeholder {
            font-size: 18px;
            color: white;
          }
        }

        &.variant {
          background-color: white;
          backdrop-filter: none;

          .input {
            width: 100%;
            padding: 24px;

            font-size: 18px;
            color: colors.$black;

            background-color: colors.$grey-100;
            border: solid 1px colors.$grey-500;
            border-radius: border-radius.$small;

            &::placeholder {
              font-size: 14px;
              color: colors.$grey-700;
            }
          }
        }

        .close-icon {
          cursor: pointer;
          margin: 20px;
        }
      }
    }
  }
}

@include breakpoints.tablet() {
  .search-modal-wrapper {
    .search-container {
      width: 100%;
    }
  }
}
</style>
