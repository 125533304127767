<template>
  <div
    v-for="{ title, dropdown } of menus"
    :key="title"
    class="menu-item"
    @click="handleMenuMobileClick(dropdown.component)"
  >
    <span class="title-item" :class="{ blue: dropdown.component === 'specialOffers' }">{{
      title
    }}</span>
    <Icon
      name="chevron"
      class="chevron-menu"
      stroke="var(--color-black)"
      width="13px"
      height="13px"
    />
  </div>
</template>

<script lang="ts" setup>
import { Social } from '@/lib/strapi-types/components/Social';

import { DropdownMenu, DropdownModel } from '@/lib/types/models/header';

defineProps<{ menus: DropdownMenu[]; socials: Social[] }>();

const emit =
  defineEmits<(event: 'handle-menu-mobile-open', component: DropdownModel['component']) => void>();

function handleMenuMobileClick(component: DropdownModel['component']): void {
  emit('handle-menu-mobile-open', component);
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';

.menu-item {
  cursor: pointer;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 14px 0;

  border-bottom: solid 0.5px colors.$grey-300;

  &:last-child {
    border-bottom: none;
  }

  .chevron-menu {
    transform: rotate(-90deg);
  }

  .title-item {
    font-size: 18px;
    font-weight: 600;

    &.blue {
      color: colors.$green;
    }
  }
}
</style>
