<template>
  <div class="mobile-list">
    <span role="button" aria-label="back-button" class="mobile-title" @click="handleBack">
      <Icon
        name="chevron"
        width="16px"
        height="16px"
        class="back-icon"
        stroke="var(--color-green)"
      />
      {{ currentParent.name }}
    </span>
    <div v-for="item in currentItems" :key="item.name">
      <component
        :is="isLink(item) ? NuxtLink : 'div'"
        class="menu-item"
        :class="setCurrentItemStyles(item)"
        :to="`${pathToRedirect(item)}`"
        @click="handleClick(item)"
      >
        <div class="info">
          <span class="title-item">
            {{ item.name ?? item.continent?.name }}
          </span>
          <span v-if="item.phone" class="phone">{{ item.phone }}</span>
        </div>
        <Icon
          v-if="item.countries?.length"
          name="chevron"
          class="chevron-menu"
          stroke="var(--color-black)"
          width="13px"
          height="13px"
        />
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { HEADER_MENUS } from '@/lib/variables';

import DROPDOWN_MENU_TYPES_ENUM from '@/lib/types/enums/dropdownEntryTypes.enum';
import HEADER_MENUS_ENUM from '@/lib/types/enums/headerMenus.enum';
import ROUTE_NAMES from '@/lib/types/enums/routeNames.enum';
import { DropdownEntry, DropdownMenu } from '@/lib/types/models/header';

import { NuxtLink } from '#components';

const props = defineProps<{
  currentComponentMenu?: DropdownMenu;
}>();

const emit = defineEmits<(event: 'back-to-root' | 'close-dropdown') => void>();

const currentItems = ref<DropdownEntry[]>([]);

function setCurrentItemStyles(item: DropdownEntry): object {
  return {
    bold: item.type === 'category' || item.type === 'cta',
    normal: item.type === 'destination' || item.type === 'month',
    gold: item.type === 'cta',
    blue: item.type === 'celebration'
  };
}

watch(
  () => props.currentComponentMenu,
  () => {
    if (props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.DESTINATION) {
      // @ts-expect-error
      currentItems.value = props.currentComponentMenu.dropdown.data.items
        // @ts-expect-error
        .filter(item => item.continent.type === 'category')
        .map(item => ({
          ...item,
          parent: { code: HEADER_MENUS_ENUM.DESTINATION, name: 'Destinations' },
          type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY
        }));
    }

    if (props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.WHEN_WHERE_TO_GO) {
      currentItems.value = [
        {
          type: DROPDOWN_MENU_TYPES_ENUM.CTA,
          name: 'Guidez-moi',
          path: ROUTE_NAMES.WHEN_WHERE_TO_GO,
          parent: { code: HEADER_MENUS_ENUM.WHEN_WHERE_TO_GO, name: 'Où et quand partir?' }
        },
        // @ts-expect-error
        ...props.currentComponentMenu.dropdown.data.items
      ];
    }

    if (
      props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.WHO_ARE_WE ||
      props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.INSPIRATIONS ||
      props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.CULTURE_AND_TRAVEL ||
      props.currentComponentMenu?.dropdown.component === HEADER_MENUS_ENUM.CONTACT_REFERENT
    ) {
      // @ts-expect-error
      currentItems.value = [...props.currentComponentMenu.dropdown.data.items];
    }
  },
  { immediate: true }
);

const currentParent = computed(
  () =>
    // @ts-expect-error
    currentItems.value[0].parent ?? currentItems.value[0].continent.parent
);

function isLink(item: DropdownEntry): boolean {
  return (
    !(
      item.parent?.code === HEADER_MENUS_ENUM.DESTINATION &&
      item?.continent?.type === DROPDOWN_MENU_TYPES_ENUM.CATEGORY
    ) || item?.countries?.length === 0
  );
}

function pathToRedirect(item: DropdownEntry): string {
  return item?.countries?.length === 0 ? item?.continent?.path ?? '' : item?.path ?? '';
}

function handleClick(item: DropdownEntry): void {
  if (isLink(item)) {
    emit('close-dropdown');
    return;
  }

  currentItems.value = props.currentComponentMenu
    ? [
        {
          type: DROPDOWN_MENU_TYPES_ENUM.CTA,
          // @ts-expect-error
          name: `Voir toutes les destinations en ${item.continent.name}`,
          // @ts-expect-error
          parent: { code: item.continent.code, name: item.continent.name }
        } as DropdownEntry,
        // @ts-expect-error
        ...item.countries
      ]
    : [];
}

function handleBack(): void {
  if (
    typeof currentParent.value?.code === 'string' &&
    HEADER_MENUS.includes(currentParent.value.code as HEADER_MENUS_ENUM)
  ) {
    emit('back-to-root');
  } else {
    const parentOfParent = props.currentComponentMenu?.dropdown.data.items.find(
      // @ts-expect-error
      item => item.code === currentParent.value?.code
    )?.parent;
    // @ts-expect-error
    currentItems.value = props.currentComponentMenu
      ? props.currentComponentMenu?.dropdown.data.items.filter(
          item => item.parent?.code === parentOfParent?.code
        )
      : [];
  }
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';

.mobile-list {
  flex-direction: column;

  .mobile-title {
    cursor: pointer;

    display: flex;
    gap: 12px;
    align-items: center;

    margin-bottom: 32px;

    font-size: 20px;
    font-weight: 600;
    color: colors.$green;

    .back-icon {
      transform: rotate(90deg);
    }
  }

  .menu-item {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 14px 0;

    border-bottom: solid 0.5px colors.$grey-300;

    &:last-child {
      border-bottom: none;
    }

    .info {
      flex-direction: column;
    }

    &.bold {
      .title-item {
        font-size: 18px;
        font-weight: 600;
      }
    }

    &.gold {
      .title-item {
        color: colors.$gold;
      }
    }

    &.blue {
      .title-item {
        color: colors.$green;
      }
    }

    &.normal {
      .title-item {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .chevron-menu {
      transform: rotate(-90deg);
    }
  }
}
</style>
