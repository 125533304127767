<template>
  <div class="destinations">
    <ul>
      <li
        v-for="dest of flattenItems"
        :key="dest.name"
        :class="{ 'space-top': dest.type === DROPDOWN_MENU_TYPES_ENUM.CATEGORY }"
      >
        <component
          :is="dest.path ? NuxtLink : 'div'"
          class="destination"
          :href="dest.path ? `/${dest.path}` : undefined"
          :class="{
            category: dest.type === DROPDOWN_MENU_TYPES_ENUM.CATEGORY
          }"
          @click="emit('item-click')"
        >
          {{ dest.name }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import DROPDOWN_MENU_TYPES_ENUM from '@/lib/types/enums/dropdownEntryTypes.enum';
import { DropdownDataByContinent, DropdownEntry } from '@/lib/types/models/header';

import { NuxtLink } from '#components';

const { data } = defineProps<{
  data: DropdownDataByContinent;
}>();

const emit = defineEmits<(event: 'item-click') => void>();

const flattenItems = computed(() => {
  const flatArray = [] as DropdownEntry[];

  data.items.forEach(item => {
    flatArray.push(item.continent);
    flatArray.push(...item.countries);
  });

  return flatArray;
});
</script>

<style lang="scss" scoped>
@use '$/colors.scss';

.destinations {
  display: block;

  width: 100%;
  max-height: 560px; // TODO: Replace by heights by max-height: min(560px, calc(100vh - 190px)); if it's necesary

  font-size: 14px;
  color: colors.$black;

  .destination {
    width: auto;

    &.category {
      width: 132px;
      font-weight: bolder;
      color: colors.$green;
    }
  }
}

ul {
  columns: 7;
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  padding: 0;
}

.space-top {
  &:not(:first-child) {
    margin-top: 14px;
  }
}

@media (width <= 1280px) {
  .destinations {
    .destination {
      width: 100px;

      &.category {
        width: 100px;
      }
    }
  }
}
</style>
