enum DROPDOWN_MENU_TYPES_ENUM {
  CTA = 'cta',
  CATEGORY = 'category',
  DESTINATION = 'destination',
  MONTH = 'month',
  CELEBRATION = 'celebration',
  CONTACT = 'contact'
}

export default DROPDOWN_MENU_TYPES_ENUM;
