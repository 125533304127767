<template>
  <div class="image-items">
    <NuxtLink
      v-for="entry of data.items"
      :key="entry.path"
      :to="entry.path"
      @click="emit('item-click')"
    >
      <div :key="entry.name" class="image-item">
        <TwicImg class="image" :src="entry.image" :alt="entry.name" ratio="2:1" />
        <div class="name">{{ capitalize(entry.name) }}</div>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { capitalize } from '@/lib/utils';

import { DropdownData } from '@/lib/types/models/header';
defineProps<{
  data: DropdownData;
}>();

const emit = defineEmits<(event: 'item-click') => void>();
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/border-radius.scss';

.image-items {
  gap: 24px;
  justify-content: center;
  width: 1020px;
  color: colors.$black;

  .image-item {
    cursor: pointer;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .image {
      width: 200px;
      height: 265px;
      border-radius: border-radius.$small;
    }

    .name {
      justify-content: center;

      width: 200px;

      font-size: 16px;
      font-weight: 600;
      color: colors.$black;
      text-align: center;
    }
  }
}
</style>
