<template>
  <main class="main" :class="{ bottom: isBottom }">
    <Header
      :data="headerData"
      :header-band="headerBand"
      :socials="socials ?? []"
      @open-home-search-modal="handleOpenHomeSearchModal"
    />
    <div v-show="!isLightBackgroundLink(route.fullPath)" class="heading" />
    <slot :header-event-payload="headerEventPayload" />

    <LazySectionNewsletterBanner v-if="newsletter" :newsletter="newsletter" />
    <LazyFooter
      :agency-infos="footer.agencyInfos"
      :continents="footer.continents"
      :socials="socials ?? []"
      :menu-elements="footer.menuElements"
      :menu-logos-elements="footer.menuLogosElements"
    />
    <LazyFooterSecondary :menu="secondaryFooterMenu" />
    <LazyScrollUpButton />
    <SpeedInsights />
  </main>
</template>

<script lang="ts" setup>
import { SpeedInsights } from '@vercel/speed-insights/nuxt';

const globalFetchedDataStore = useGlobalFetchedDataStore();

const route = useRoute();
const { isShowMainHeader, isHeaderBandDisplayed } = storeToRefs(useRootStore());

const loadGlobalStore = useAsyncData(() => {
  return globalFetchedDataStore.fetchPeriods();
});

const secondaryFooterMenu = [
  {
    name: 'Conditions de vente',
    slug: 'conditions-de-vente'
  },
  {
    name: 'Mentions légales et CGU',
    slug: 'mentions-legales-et-cgu'
  },
  {
    name: 'Confidentialité',
    slug: 'confidentialite'
  },
  {
    name: 'Chartes des cookies',
    slug: 'chartes-cookies'
  },
  {
    name: 'Plan du site',
    slug: 'plan-du-site'
  },
  {
    name: 'Contact',
    slug: 'contact'
  }
];

const [header, businessHeader, { newsletter, socials, headerBand }] = await Promise.all([
  useHeader(),
  useBusinessHeader(),
  useCommon(),
  loadGlobalStore
]);
useRootStore().setIsHeaderBandDisplayed(Boolean(headerBand?.isDisplayed));

const desktopHeaderHeight = 60;
const headerBandHeight = 44;

const headerHeight = computed(() => {
  if (isShowMainHeader.value) {
    const bandHeight = isHeaderBandDisplayed.value ? headerBandHeight : 0;
    return `${desktopHeaderHeight + bandHeight}px`;
  }
  return '0px';
});

const headerData = useHeaderData({ header, businessHeader });
const footer = useFooter();

const isBottom = computed(() => route.name === 'product-id');

const headerEventPayload = ref(null);

const handleOpenHomeSearchModal = (payload: any): void => {
  headerEventPayload.value = payload;
};

watchEffect(() => {
  useHead({
    link: [
      {
        rel: 'canonical',
        href: useRuntimeConfig().public.site.url + route.path
      }
    ]
  });
});
</script>

<style lang="scss" scoped>
@use '$/spacings.scss';

.main {
  .heading {
    padding-top: v-bind(headerHeight);
  }

  &.bottom {
    margin-bottom: 73px;
  }
}

@media (width <= 1024px) {
  .main {
    .heading {
      padding-top: calc(v-bind(headerHeight) - 10px);
    }
  }
}

@media (width <= 768px) {
  .main {
    &.bottom {
      margin-bottom: 34px;
    }
  }
}
</style>
