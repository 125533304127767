<template>
  <div v-if="isOpen" class="appointment-modal-wrapper" @click="emit('close')">
    <div class="appointment-modal"><DevRoutePlaceholder /></div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<(event: 'close') => void>();

function onEscapeKeyDown(e: KeyboardEvent): void {
  if (e.key === 'Escape') {
    emit('close');
  }
}

onMounted(() => {
  document.addEventListener('keydown', onEscapeKeyDown);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', onEscapeKeyDown);
});
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';

.appointment-modal-wrapper {
  position: fixed;
  z-index: 110;
  inset: 0;

  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0 0 0 / 40%);

  .appointment-modal {
    padding: 200px;
    background-color: white;
    border-radius: border-radius.$default;
  }
}
</style>
