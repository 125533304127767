<template>
  <div class="header-band">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="text" v-html="content" />
    <Icon name="close" width="12px" stroke="white" class="close" @click="emit('close', $event)" />
  </div>
</template>

<script lang="ts" setup>
import { renderMarkdown } from '@/lib/markdown';

import { HeaderBand } from '@/lib/strapi-types/components/HeaderBand';

const { band } = defineProps<{
  band: HeaderBand;
}>();

const emit = defineEmits<(event: 'close', e: MouseEvent) => void>();

const { bgColor, color } = band;
const content = ref(renderMarkdown(band.label).rendered);
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/text-ellipsis.scss';

.header-band {
  display: flex;

  padding: 10px 0;

  font-size: 14px;
  color: white;

  background-color: v-bind(bgColor);

  .close {
    cursor: pointer;
    right: 0;
    padding: 4px 16px;
  }

  .text {
    @include text-ellipsis.multi-line(1);

    display: flex;
    flex-grow: 1;
    justify-content: center;

    margin-top: 2px;
    padding: 0 20px;

    font-size: 14px;
    color: v-bind(color);
    text-align: center;
    white-space: nowrap;

    :deep(a) {
      text-decoration: underline;
    }
  }
}

@media (width <= 768px) {
  .header-band {
    .text {
      @include text-ellipsis.multi-line(1);

      font-size: 12px;
      text-align: center;
      word-break: break-word;
      white-space: nowrap;
      white-space: normal;
    }
  }
}
</style>
