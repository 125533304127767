import { BusinessHeader } from '@/lib/strapi-types/BusinessHeader';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export default async function useBusinessHeader() {
  const { findOne } = useStrapi();

  const { data: header } = await useAsyncData(BackEndpoints.BUSINESS_HEADER, () =>
    findOne<BusinessHeader['attributes']>(BackEndpoints.BUSINESS_HEADER, {
      populate: {
        whoAreWeMenu: { fields: ['*'], populate: { image: { fields: ['url'] } } },
        refsAndTestimonialsMenu: { fields: ['*'], populate: { image: { fields: ['url'] } } },
        travelsMenu: { fields: ['*'], populate: { image: { fields: ['url'] } } }
      }
    })
  );

  return {
    whoAreWeMenu: header.value?.data.attributes.whoAreWeMenu,
    refsAndTestimonialsMenu: header.value?.data.attributes.refsAndTestimonialsMenu,
    travelsMenu: header.value?.data.attributes.travelsMenu
  };
}
