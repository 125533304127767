import { Header } from '@/lib/strapi-types/Header';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export default async function useHeader() {
  const { findOne } = useStrapi();

  const { data: header } = await useAsyncData(BackEndpoints.HEADER, () =>
    findOne<Header['attributes']>(BackEndpoints.HEADER, {
      populate: {
        destinationMenu: {
          populate: {
            destinations: {
              fields: ['*'],
              populate: {
                links: {
                  fields: ['name', 'url']
                }
              }
            }
          }
        },
        headerBand: {
          fields: ['*']
        },
        whoAreWeMenu: { fields: ['*'], populate: { image: { fields: ['url'] } } },
        cultureAndTravelMenu: { fields: ['*'], populate: { image: { fields: ['url'] } } },
        whereWhenToGoMenu: { fields: ['*'] },
        inspirationMenu: {
          fields: ['*'],
          filters: {
            isDisplayed: true
          },
          populate: { image: { fields: ['url'] } }
        }
      }
    })
  );

  return {
    destinationMenu: header.value?.data.attributes.destinationMenu,
    inspirationMenu: header.value?.data.attributes.inspirationMenu,
    whoAreWeMenu: header.value?.data.attributes.whoAreWeMenu,
    whereWhenToGoMenu: header.value?.data.attributes.whereWhenToGoMenu,
    cultureAndTravelMenu: header.value?.data.attributes.cultureAndTravelMenu
  };
}
